import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";

import { Full_ROUTES } from "./shared/routes/full-layout.routes";
import { CONTENT_ROUTES } from "./shared/routes/content-layout.routes";

import { AuthGuard } from './shared/auth/auth-guard.service';
import { PUBLIC_ROUTES } from './shared/routes/public.routes';
import { ADMIN_ROUTES } from './shared/routes/admin.routes';
import { MEDICO_ROUTES } from './shared/routes/medico.routes';
import { PROMOTER_ROUTES } from './shared/routes/promotor.routes';
import { MAYORISTA_ROUTES } from './shared/routes/mayorista.routes';
import { SELLER_ROUTES } from './shared/routes/seller.routes';
import { PROVIDER_ROUTES } from './shared/routes/provider.routes';
import { LOGISTICS_ROUTES } from './shared/routes/logistics.routes';

const appRoutes: Routes = [
  { path: '', redirectTo: 'public/login', pathMatch: 'full' },
  { path: '', component: ContentLayoutComponent, data: {title: 'Public Views'}, children: PUBLIC_ROUTES },
  { path: '', component: FullLayoutComponent, data: {title: 'Admin Views'}, children: ADMIN_ROUTES, canActivate: [AuthGuard] },
  { path: '', component: FullLayoutComponent, data: {title: 'Medico Views'}, children: MEDICO_ROUTES, canActivate: [AuthGuard] },
  { path: '', component: FullLayoutComponent, data: {title: 'Promotor Views'}, children: PROMOTER_ROUTES, canActivate: [AuthGuard] },
  { path: '', component: FullLayoutComponent, data: {title: 'Mayorista Views'}, children: MAYORISTA_ROUTES, canActivate: [AuthGuard] },
  { path: '', component: FullLayoutComponent, data: {title: 'Seller Views'}, children: SELLER_ROUTES, canActivate: [AuthGuard] },
  { path: '', component: FullLayoutComponent, data: {title: 'Provider Views'}, children: PROVIDER_ROUTES, canActivate: [AuthGuard] },
  { path: '', component: FullLayoutComponent, data: {title: 'Logistics Views'}, children: LOGISTICS_ROUTES, canActivate: [AuthGuard] },
  { path: '**', redirectTo: '' }
];

// const appRoutes: Routes = [
//   {
//     path: '',
//     redirectTo: 'pages/login',
//     pathMatch: 'full',
//   },
//   { path: '', component: FullLayoutComponent, data: { title: 'full Views' }, children: Full_ROUTES, canActivate: [AuthGuard] },
//   { path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES },
//   {
//     path: '**',
//     redirectTo: 'pages/error'
//   }
// ];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
