
const _host = 'https://api.gvccosmetics.com/';
//const _host = 'http://localhost:8000/';

export const config = {
    host: _host,
    apiUrl: _host.concat('api/'),
    fileUrl: _host.concat('api/fileupload'),
    local: 'https://app.gvccosmetics.com/'
    //local: 'http://localhost:4200/'
  };