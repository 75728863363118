import { Routes, RouterModule } from '@angular/router';

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const MAYORISTA_ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'mayorista',
    pathMatch: 'full'
  },
  {
    path: 'mayorista',
    loadChildren: () => import('../../pages/mayorista/mayorista.module').then(x => x.MayoristaModule)
  }
];