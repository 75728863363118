import { Routes, RouterModule } from '@angular/router';

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const PROVIDER_ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'provider',
    pathMatch: 'full'
  },  
  {
        path: 'provider',
        loadChildren: () => import('../../pages/provider/provider.module').then(x => x.ProviderModule)
      }
];