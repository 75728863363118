import { Routes, RouterModule } from '@angular/router';

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const PUBLIC_ROUTES: Routes = [
    {
      path: '',
      redirectTo: 'public',
      pathMatch: 'full'
    },
    {
      path: 'public',
      loadChildren: () => import('../../pages/public/public.module').then(x => x.PublicModule)
    }
];