import { RouteInfo } from './vertical-menu.metadata';

//Sidebar menu Routes and data
// export const ROUTES: RouteInfo[] = [
//   {
//     path: '/page', title: 'Page', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
//   },
//   {
//     path: '', title: 'Menu Levels', icon: 'ft-align-left', class: 'has-sub', badge: '3', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
//     submenu: [
//         { path: '/YOUR-ROUTE-PATH', title: 'Second Level', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
//         {
//             path: '', title: 'Second Level Child', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
//             submenu: [
//                 { path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.1', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
//                 { path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.2', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
//             ]
//         },
//     ]
// },
// ];
export const ADMIN_ROUTES: RouteInfo[] = [

  {
      path: '/admin/dashboard', title: 'Dashboard', icon: 'ft-layout', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu:[]
  },
  {
      path: '', title: 'Reportes', icon: 'ft-file-text', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
      submenu:[
          {
              path: '/admin/report/payments', title: 'Comisiones', icon: 'ft-file', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
          },
          {
              path: '/admin/report/orders', title: 'Pedidos', icon: 'ft-clipboard', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
          },
          {
              path: '/admin/report/sales', title: 'Ventas', icon: 'ft-activity', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
          },
          // {
          //     path: '/admin/report/utilities', title: 'Utilidades', icon: 'ft-calendar', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
          // },
          {
              path: '/admin/report/providers', title: 'C. Proveedores', icon: 'fab fa-ello', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
          }
      ]
  },
  {
      path: '', title: 'Catálogos', icon: 'ft-settings', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
      submenu:[
          {
              path: '/admin/catalog/users', title: 'Usuarios', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
          },
          {
              path: '/admin/catalog/products', title: 'Productos', icon: 'ft-box', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
          },
          {
              path: '/admin/catalog/categories', title: 'Categorías', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
          },
          {
              path: '/admin/catalog/tools', title: 'Herramientas', icon: 'ft-scissors', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
          },
          {
              path: '/admin/catalog/trainings', title: 'Entrenamientos', icon: 'ft-play', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
          },
          {
              path: '/admin/catalog/banks', title: 'Bancos', icon: 'ft-bold', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
          },
          {
              path: '/admin/catalog/banners', title: 'Banners', icon: 'ft-monitor', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
          },
          {
              path: '/admin/catalog/providers', title: 'Proveedores', icon: 'ft-book', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
          },
          {
              path: '/admin/catalog/shippings', title: 'Envíos', icon: 'fas fa-dolly', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
          },
          {
              path: '/admin/catalog/salesagents', title: 'Vendedores', icon: 'fas fa-male', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
          },
      ]
  },
  {
      path: '/admin/inventory', title: 'Inventario', icon: 'ft-layout', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu:[]
  },
  {
      path: '/public/logout', title: 'Salir', icon: 'ft-log-out', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu:[]
  }
];

export const MEDICO_ROUTES: RouteInfo[] = [
  {
      path: '/cliente/dashboard', title: 'Dashboard', icon: 'ft-layout', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu:[]
  },
  {
      path: '/cliente/store', title: 'Tienda', icon: 'ft-shopping-cart', class: '', badge: '', badgeClass: 'badge badge-pill badge-warning float-right mr-1 mt-1', isExternalLink: false, submenu:[]
  },
  {
      path: '/cliente/orders', title: 'Mis Pedidos', icon: 'ft-clipboard', class: '', badge: '', badgeClass: 'badge badge-pill badge-warning float-right mr-1 mt-1', isExternalLink: false, submenu:[]
  },
  {
      path: '/cliente/collaborators', title: 'Colaboradores', icon: 'ft-users', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu:[]
  },
  {
      path: '/cliente/tools', title: 'Herramientas', icon: 'fas fa-wrench', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu:[]
  },
  {
      path: '/cliente/training', title: 'Entrenamientos', icon: 'fas fa-dumbbell', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu:[]
  },
  {
      path: '/cliente/profile', title: 'Perfil', icon: 'far fa-id-badge', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu:[]
  },
  {
      path: '/public/logout', title: 'Salir', icon: 'ft-log-out', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu:[]
  },
];

export const PROMOTOR_ROUTES: RouteInfo[] = [
  {
      path: '/promoter/dashboard', title: 'Dashboard', icon: 'ft-layout', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu:[]
  },
  {
      path: '/promoter/store', title: 'Tienda', icon: 'ft-shopping-cart', class: '', badge: '', badgeClass: 'badge badge-pill badge-warning float-right mr-1 mt-1', isExternalLink: false, submenu:[]
  },
  {
      path: '/promoter/orders', title: 'Pedidos', icon: 'ft-clipboard', class: '', badge: '', badgeClass: 'badge badge-pill badge-warning float-right mr-1 mt-1', isExternalLink: false, submenu:[]
  },
  {
      path: '/promoter/collaborators', title: 'Colaboradores', icon: 'ft-users', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu:[]
  },
  {
      path: '/promoter/profile', title: 'Perfil', icon: 'far fa-id-badge', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu:[]
  },
  {
      path: '/public/logout', title: 'Salir', icon: 'ft-log-out', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu:[]
  },
];

export const MAYORISTA_ROUTES: RouteInfo[] = [
  {
      path: '/mayorista/dashboard', title: 'Dashboard', icon: 'ft-layout', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu:[]
  },
  {
      path: '/mayorista/store', title: 'Tienda', icon: 'ft-shopping-cart', class: '', badge: '', badgeClass: 'badge badge-pill badge-warning float-right mr-1 mt-1', isExternalLink: false, submenu:[]
  },
  {
      path: '/mayorista/orders', title: 'Mis Pedidos', icon: 'ft-clipboard', class: '', badge: '', badgeClass: 'badge badge-pill badge-warning float-right mr-1 mt-1', isExternalLink: false, submenu:[]
  },
  {
      path: '/mayorista/profile', title: 'Perfil', icon: 'far fa-id-badge', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu:[]
  },
  {
      path: '/public/logout', title: 'Salir', icon: 'ft-log-out', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu:[]
  },
];

export const DISTRIBUIDOR_ROUTES: RouteInfo[] = [];

export const SELLER_ROUTES: RouteInfo[] = [
  {
      path: '/seller/dashboard', title: 'Dashboard', icon: 'ft-layout', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu:[]
  },
  {
      path: '/seller/orders', title: 'Pedidos', icon: 'ft-shopping-cart', class: '', badge: '', badgeClass: 'badge badge-pill badge-warning float-right mr-1 mt-1', isExternalLink: false, submenu:[]
  },
  {
      path: '', title: 'Reportes', icon: 'ft-file-text', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
      submenu:[
          {
              path: '/seller/reports/orders', title: 'Pedidos', icon: 'ft-clipboard', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
          }
      ]
  },
  {
      path: '/seller/tools', title: 'Herramientas', icon: 'fas fa-wrench', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu:[]
  },
  {
      path: '/seller/trainings', title: 'Entrenamientos', icon: 'fas fa-dumbbell', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu:[]
  },
  {
      path: '/public/logout', title: 'Salir', icon: 'ft-log-out', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu:[]
  },
];

export const PROVIDER_ROUTES: RouteInfo[] = [
  {
      path: '/provider/orders', title: 'Pedidos', icon: 'ft-file-text', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: []
  },
  {
      path: '/provider/shippings', title: 'Envíos', icon: 'fas fa-dolly', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: []
  },
  {
      path: '/public/logout', title: 'Salir', icon: 'ft-log-out', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu:[]
  }
];

export const LOGISTICS_ROUTES: RouteInfo[] = [
  {
      path: '/logistics/orders', title: 'Pedidos', icon: 'ft-file-text', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: []
  },
  {
      path: '/logistics/entry-merch', title: 'Entrada de Mercancía', icon: 'ft-box', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: []
  },
  {
      path: '/logistics/inventory', title: 'Inventario', icon: 'ft-layers', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: []
  },
  {
      path: '/logistics/batch', title: 'Lotes', icon: 'ft-server', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: []
  },
  {
      path: '/public/logout', title: 'Salir', icon: 'ft-log-out', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu:[]
  }
];
