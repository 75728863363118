import { Routes, RouterModule } from '@angular/router';

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const ADMIN_ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'admin',
    pathMatch: 'full'
  },  
  {
        path: 'admin',
        loadChildren: () => import('../../pages/admin/admin.module').then(x => x.AdminModule)
      }
];