import { Injectable } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { AuthService } from '../auth/auth.service';
import { userModel } from '../models/user';

@Injectable()
export class InitializerProviderService {

    constructor(private localStorage: LocalStorage, private authService: AuthService) {

    }

    load() {
        console.log('USUARIO CARGADO');
        return new Promise((resolve, reject) => {
            this.localStorage.getItem('user')
                .subscribe((user: userModel) => {
                  if(user){
                      this.authService.id = user.id;
                      this.authService.idprofile = user.id_profile;
                      this.authService.token = user.token;
                      console.log('INIT TOKEN', this.authService.token);
                  }
                  resolve(true);
                });
        })
    }
}
