import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { config } from 'environments/config';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Accept': 'application/json'
  })
};

@Injectable()
export class AuthService {
  token: string;
  idprofile: number;
  id: number;

  constructor(public http: HttpClient) {
    this.token = '';
    this.idprofile = 0;
    this.id = 0;
  }

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(email: string, password: string) : Observable<any> {
    //your code for checking credentials and getting tokens for for signing in user
    return this.http.post(config.apiUrl + "user/login", {
      email: email,
      password: password
    }, httpOptions);
  }

  logout() {   
    this.token = null;
  }

  getToken() {    
    return this.token;
  }

  isAuthenticated(): Observable<any>{
    // here you can check if user is authenticated or not through his token
    console.log('AUTH SERVICE', this.getToken());
    return this.http.post(config.apiUrl.concat('users/is-active'), {}, {headers: {
      'Content-Type': 'application/json',
      'Authorization': this.getToken()
    }});
  }
}
