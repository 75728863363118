import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LocalStorage } from '@ngx-pwa/local-storage';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router, private storage: LocalStorage) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>{
    return this.authService.isAuthenticated().pipe(catchError(val => of(`ERROR: ${val}`).pipe(map(err => {
      return {status: false};
    }))), map(res => {
      if(!res.status){
        this.storage.clear();
        this.router.navigate(['public', 'login']);
      }
      return res.status;
    }));
  }
}
