import { Routes, RouterModule } from '@angular/router';

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const LOGISTICS_ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'logistics',
    pathMatch: 'full'
  },  
  {
    path: 'logistics',
    loadChildren: () => import('../../pages/logistics/logistics.module').then(x => x.LogisticsModule)
  }
];