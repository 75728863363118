import { Routes, RouterModule } from '@angular/router';

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const MEDICO_ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'cliente',
    pathMatch: 'full'
  },  
  {
        path: 'cliente',
        loadChildren: () => import('../../pages/doctor/doctor.module').then(x => x.DoctorModule)
      }
];