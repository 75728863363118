import { Routes, RouterModule } from '@angular/router';

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const PROMOTER_ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'promoter',
    pathMatch: 'full'
  },  
  {
        path: 'promoter',
        loadChildren: () => import('../../pages/promoter/promoter.module').then(x => x.PromoterModule)
      }
];