import { Routes, RouterModule } from '@angular/router';

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const SELLER_ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'public',
    pathMatch: 'full'
  },  
  {
        path: 'seller',
        loadChildren: () => import('../../pages/seller/seller.module').then(x => x.SellerModule)
      }
];